import React from 'react'
import SEO from "../components/atoms/seo"
import posed from 'react-pose'
import styled from 'styled-components'
import FullPageSlide from '../components/organisms/FullPageSlide'
import ScrollDown from '../components/atoms/ScrollDown'
import Services from '../components/organisms/sections/Services'
import BrandName from '../components/atoms/BrandName'

const List = posed.ul({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
})

const Item = posed.li({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

const SectionStyled = styled.section`
  min-width: 100vw;
  min-height: 100vh;
`

const Background = styled.section`
  background: linear-gradient(135deg, transparent 50%, #062f4f 50%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`

function ServicesPage() {
  return (
    <FullPageSlide slideNumber={3} prev={'/about/'} next={'/knowledge/'}>
      <SEO
        title="Services"
        keywords={[`services`, `soulweb`, `solutions`]}
      />

      <Background/>
      <BrandName/>

      <SectionStyled>
        <Services/>
      </SectionStyled>

      {/*}<ScrollDown scrollTo={'/knowledge/'}/>*/}
    </FullPageSlide>
  )
}

export default ServicesPage
