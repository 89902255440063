import React from 'react'
import posed from 'react-pose'

const Container = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: 50, opacity: 0 },
})

const ServiceBlock = props => {
  const { template } = props

  return(
    <Container className={"flex-basis-1/3 pb-8 lg:px-5"}>
      <h3 className="text-white font-mono pb-2 lg:pb-4 text-lg lg:text-2xl">{props.title}</h3>
      <p className="text-white font-mono text-sm">{props.description}</p>
    </Container>
  )
}

export default ServiceBlock
