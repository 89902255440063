import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import posed, { PoseGroup } from 'react-pose'
import ServiceBlock from '../../molecules/ServiceBlock'

const Container = posed.div({
  enter: { staggerChildren: 200 },
  exit: { staggerChildren: 50 },
})

const H1 = posed.h1({
  enter: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 },
})

class Services extends Component {
  state = { isVisible: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible
      });
    }, 2500);
  }

  render() {
    const { isVisible } = this.state;
    const props = this.props;

    return(
      <StaticQuery
        query={graphql`
          query ServiceSection {
            homeServicesJson{
              services{
                title
                blocks{
                  id
                  title
                  description
                  buttonText
                  template
                }
              }
            }
          }
        `}
        render={ data => {
          const blocks = data.homeServicesJson.services.blocks

          return (
            <PoseGroup>
              {isVisible
                ? <Container key="serviceBlocks" className="px-10 pt-16 md:p-20 lg:px-32">
                    <H1 className="text-white pb-10 font-mono text-2xl lg:text-4xl">{data.homeServicesJson.services.title}</H1>
                    <div className={`flex flex-wrap flex-col lg:flex-row`}>
                      {printBlocks({data, props})}
                    </div>
                  </Container>
                : null
              }
            </PoseGroup>
          )}
        }
      />
    )
  }
}

const printBlocks = ({data, props}) => {
  const blocks = data.homeServicesJson.services.blocks

  const renderedBlocks = blocks.map((block, i) => (
    <ServiceBlock
      key={i}
      template={block.template || 1}
      title={`${block.title}`}
      description={block.description}
      buttonText={block.buttonText}
    />
  ))

  return renderedBlocks
}

export default Services;
